import React from "react";
import { observer, inject } from 'mobx-react';
import { withRouter } from "react-router-dom";
import { firebaseApp } from "../Utils/FirebaseInit";


const context = "Dashboard.js";

var store = null;
var self = null;

@inject("appStore")
@observer
class Dashboard extends React.Component {

    constructor(props) {
        super(props);
        store = this.props.appStore;
        self = this;


    }



    componentDidMount = () => {
        console.log("Page : ", context)
        var is_authenticate = store.getIsAuthenticated;
        if (!is_authenticate) {
            this.props.history.push("/login")
        }
    }

    componentWillUnmount = () => {
        store.setUpcommingEventsData(null)
    }

    handleFindResultButtonClick = (e) => {
        var date = document.getElementById("inputDate").value;
        console.log("inputDate = >", date)
        if (date.trim() === "") {
            alert("Invalid Date")
            return;
        } else {
            store.setUpcommingEventsData(null)
            var firebase = firebaseApp();
            var db = firebase.firestore();
            var docRef = db.collection("upcoming").doc(date);

            docRef.get().then((doc) => {
                if (doc.exists) {
                    console.log("Document data:", doc.data());
                    store.setUpcommingEventsData(doc.data())
                } else {
                    // doc.data() will be undefined in this case
                    console.log("No such document!");
                    store.setUpcommingEventsData("NO_DATA_FOUND")
                }
            }).catch((error) => {
                console.log("Error getting document:", error);
            });
        }

    }

    generatetableUi = (upcommingEventssData, configData) => {
        console.log("tableUUiData => ", upcommingEventssData)
        if (upcommingEventssData === "NO_DATA_FOUND") {
            console.log("true")
            var ui = configData.timings.map(function (content, index) {
                return (
                    <tr>
                        <td>{content}</td>
                        <td><input id={"input_" + content} type="text" maxLength="4"></input></td>
                    </tr>
                )
            });

            return ui;
        } else {
            var ui = upcommingEventssData.data.map(function (content, index) {
                return (
                    <tr>
                        <td>{content.time}</td>
                        <td><input id={"input_" + content.time} type="text" maxLength="4" defaultValue={content.value}></input></td>
                    </tr>
                )
            });

            return ui;
        }
    }

    handleSaveResultButtonClick = (e) => {
        var date = document.getElementById("inputDate").value;
        if (date.trim() === "") {
            alert("Invalid Date")
            return;
        } else {
            var configData = store.getFbConfigData;
            var res = []
            configData.timings.map(function (content, index) {
                var jsonObject = {}
                jsonObject['date'] = date;
                jsonObject['time'] = content;
                jsonObject['date_time'] = new Date((date).toString() + " " + (content).toString());
                jsonObject['value'] = document.getElementById("input_" + content).value;
                res.push(jsonObject)
            });
            console.log("Result Json OObject : ", res)
            var finalData = {
                "date": date,
                "data": res
            }

            var firebase = firebaseApp();
            var db = firebase.firestore();
            db.collection("upcoming").doc(date).set(finalData).then(function () {
                window.showDefaultSnackbar("DONE !")
            }).catch(function (err) {
                window.showDefaultSnackbar("ERROR : D-101 !")
            })

        }
    }

    render() {
        var upcommingEventssData = store.getUpcommingEventsData;
        var configData = store.getFbConfigData;
        var table_ui = null
        if (upcommingEventssData) {
            table_ui = this.generatetableUi(upcommingEventssData, configData)
        }
        return (
            <div className="center-item" style={{ backgroundColor: "azure" }}>
                <div style={{ textAlign: "center", margin: "auto", width: "50%", paddingTop: 50, marginBottom: 50 }}>
                    <h1>Goa Fatafat Lottery Results</h1>
                    <input className="center-item" style={{ padding: 10 }} type="date" id="inputDate"></input>
                    <button onClick={(e) => this.handleFindResultButtonClick(e)} className="green-button"> Find Result </button>
                </div>
                <table id="customers" className="center-item" style={{ width: '80%' }}>
                    <tr>
                        <th>Time</th>
                        <th>Score</th>
                    </tr>
                    {table_ui}
                </table>
                <div className="center-item" style={{ textAlign: "center", margin: "auto", width: "50%", paddingTop: 50, marginBottom: 50 }}>
                    <button style={{ marginBottom: 50 }} className="center-item" onClick={(e) => this.handleSaveResultButtonClick(e)} className="green-button"> Save </button>
                </div>

            </div>
        )

    }
}

export default withRouter(Dashboard);
