export var checkUserEmail = (userEmail) => {
    var userEmail = userEmail;
    var userEmailFormate = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var flag;
    if (userEmail.match(userEmailFormate)) {
        flag = true;
    } else {
        flag = false;
    }
    return flag;
}
// xxxxxxxxxx Password Validation xxxxxxxxxx
export var checkUserPassword = (userPassword) => {
    //Your password must be uppercase, lowercase and more than 10 characters.
    var userPassword = userPassword;
    var userPasswordFormate = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{10,}/;
    var flag;
    if (userPassword.match(userPasswordFormate)) {
        flag = true;
    } else {
        flag = false;
    }
    return flag;
}