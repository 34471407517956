import React from "react";
import { observer, inject } from 'mobx-react';
import { withRouter, Link } from "react-router-dom";
import { firebaseApp } from "../Utils/FirebaseInit";
import { checkUserEmail, checkUserPassword } from "../Utils/FormValidator";

const context = "Login.js";

var store = null;
var self = null;

@inject("appStore")
@observer
class Login extends React.Component {

    constructor(props) {
        super(props);
        store = this.props.appStore;
        self = this;
        this.state = {
            isLoading: false
        }
    }



    componentDidMount = () => {
        console.log("Page : ", context)
        // setTimeout(function () {
        //     store.setAuthentication(true)
        //     console.log("Done")
        //     self.props.history.push("/home")
        // }, 10000);
    }

    handleUserDefaultLogin = (e) => {
        var user_email = document.getElementById("user_email").value;
        var user_password = document.getElementById("user_password").value;

        var is_email_valid = checkUserEmail(user_email);
        var is_password_valid = true;

        if ((is_email_valid) && (is_password_valid)) {
            this.setState({
                isLoading: true
            })
            var firebase = firebaseApp();
            firebase.auth().signInWithEmailAndPassword(user_email, user_password)
                .then((userCredential) => {
                    // Signed in
                    var user = userCredential.user;
                    // ...
                    self.setState({
                        isLoading: false
                    })
                })
                .catch((error) => {
                    self.setState({
                        isLoading: false
                    })
                    var errorCode = error.code;
                    var errorMessage = error.message;
                    console.log("errorCode => ", errorCode)
                    if (errorCode === "auth/user-not-found") {
                        window.showDefaultSnackbar("Seems you are a new user, please Signup first !")
                    } else if (errorCode === "auth/wrong-password") {
                        window.showDefaultSnackbar("Password is invalid for the user !")
                    } else {
                        window.showDefaultSnackbar(errorMessage)
                    }

                });
        } else {
            if (!is_email_valid) {
                window.showDefaultSnackbar("Please check your email id")
            } if (!is_password_valid) {
                window.showDefaultSnackbar("Your password must be uppercase, lowercase and more than 10 characters.")
            }
        }

    }

    handleGoogleSignIn = (e) => {
        var firebase = firebaseApp();
        var provider = new firebase.auth.GoogleAuthProvider();
        firebase.auth().signInWithRedirect(provider);
        firebase.auth()
            .getRedirectResult()
            .then((result) => {
                if (result.credential) {
                    /** @type {firebase.auth.OAuthCredential} */
                    var credential = result.credential;

                    // This gives you a Google Access Token. You can use it to access the Google API.
                    var token = credential.accessToken;
                    // ...
                }
                // The signed-in user info.
                var user = result.user;
            }).catch((error) => {
                // Handle Errors here.
                var errorCode = error.code;
                var errorMessage = error.message;
                // The email of the user's account used.
                var email = error.email;
                // The firebase.auth.AuthCredential type that was used.
                var credential = error.credential;
                // ...
            });
    }

    render() {
        return (
            <div>
                <div className="center-block w-xxl w-auto-xs p-y-md">
                    <div className="navbar">
                        <div className="pull-center">
                            <div ui-include="'../views/blocks/navbar.brand.html'"></div>
                        </div>
                    </div>
                    <div className="p-a-md box-color r box-shadow-z1 text-color m-a">
                        <div className="m-b text-sm">
                            Sign in with your Admin Portal
                        </div>

                        <div className="md-form-group float-label">
                            <input type="email" id="user_email" className="md-input" ng-model="user.email" required />
                            <label>Email</label>
                        </div>
                        <div className="md-form-group float-label">
                            <input type="password" id="user_password" className="md-input" ng-model="user.password" required />
                            <label>Password</label>
                        </div>

                        {this.state.isLoading ? <button onClick={(e) => this.handleUserDefaultLogin(e)} className="btn primary btn-block p-x-md" disabled>Sign in</button>
                            : <button onClick={(e) => this.handleUserDefaultLogin(e)} className="btn primary btn-block p-x-md" >Sign in</button>}


                    </div>




                </div>
            </div>
        )

    }
}

export default withRouter(Login);
