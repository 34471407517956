import React from "react";
import { observer, inject } from 'mobx-react';
import { withRouter } from "react-router-dom";
import { logoutUser } from "../Utils/FirebaseInit";
const context = "Logout.js";

var store = null;
var self = null;

@inject("appStore")
@observer
class Logout extends React.Component {

    constructor(props) {
        super(props);
        store = this.props.appStore;
        self = this;
    }



    componentDidMount = () => {
        console.log("Page : ", context)

        logoutUser()
        this.props.history.push("/")
    }

    render() {
        return (
            <div>

            </div>
        )

    }
}

export default withRouter(Logout);
