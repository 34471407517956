var app_store = null;

export var initPageLoadingStore = (appStore) => {
    app_store = appStore;
    return true;
}

export var showPageLoading = (flag, msg = "") => {
    app_store.setIsPageLoading(flag, msg)
    console.log("DOne Set Page Loading")
    return true;
}