import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyC07YC6I7VYjbfxkpIssKUHHPvachEk91A",
    authDomain: "elto-4b7d6.firebaseapp.com",
    projectId: "elto-4b7d6",
    storageBucket: "elto-4b7d6.appspot.com",
    messagingSenderId: "963859772761",
    appId: "1:963859772761:web:ce5ca52d56fee0a9324848"
};



try {
    firebase.initializeApp(firebaseConfig);
} catch (error) {

}

export var firebaseApp = () => {
    return firebase;
}

export var logoutUser = () => {
    firebase.auth().signOut().then(function () {
        // Sign-out successful.
        console.log("signed out successfully, ");
        // store.setAuthentication(false)
        // self.props.history.push("/login")
    }).catch(function (error) {
        console.log("Error in signing out")
    });

    return true;
}

