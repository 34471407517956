import React, { Component } from 'react';
import AppHelperStore from './AppHelperStore/appHelperStore';
import { Provider, observer, inject } from 'mobx-react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import LoadingOverlay from 'react-loading-overlay';
import Home from './Pages/Home';
import FirebaseHandler from './Pages/FirebaseHandler';
import Login from './Pages/Login';

import { initPageLoadingStore, showPageLoading } from './Utils/PageLoading';

import Dashboard from './Pages/Dashboard';
import ResultHistory from './Pages/ResultHistory';
import Logout from './Pages/Logout';

const appStore = new AppHelperStore();

// @inject("appStore")
@observer
class App extends Component {
  constructor(props) {
    super(props)
  }

  // componentWillMount = () => {
  // }
  componentDidMount = () => {
    initPageLoadingStore(appStore)
    // showPageLoading(true, "Hi...")


  }

  render() {
    var is_authenticate = appStore.getIsAuthenticated;
    console.log("is authenticated ::::: ", is_authenticate)
    var route_data = (
      <div>
        <Route exact path="/login" component={Login} />
        <Route exact path="/logout" component={Logout} />
        <Route exact path="/" component={Home} />
        <Route exact path="/history" component={ResultHistory} />
        <Route exact path="/dashboard" component={Dashboard} />
      </div>
    )
    // if (is_authenticate) {
    //   route_data = (<div>
    //     <Route path="/login" component={Login} />
    //     <Route path="/" component={Home} />
    //     <Route path="/history" component={Login} />
    //     <Route path="/dashboard" component={Dashboard} />
    //   </div>)
    // } else {
    //   route_data = (
    //     <div>
    //       <Route path="/login" component={Login} />
    //       <Route path="/" component={Home} />
    //       <Route path="/history" component={Login} />
    //       <Route path="/dashboard" component={Dashboard} />
    //     </div>)
    // }

    return (
      <LoadingOverlay
        style={{ height: window.innerHeight, width: window.innerWidth }}
        active={appStore.isPageLoading}
        spinner
        text={appStore.loadingMessage}
      >
        <Provider appStore={appStore}>

          <Router>

            <Switch>
              {route_data}
            </Switch>
            <FirebaseHandler />

          </Router>

        </Provider>
      </LoadingOverlay>

    );

  }
}

export default App;
