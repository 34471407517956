import React from "react";
import { observer, inject } from 'mobx-react';
import { withRouter } from "react-router-dom";
import { firebaseApp } from "../Utils/FirebaseInit";
// import { query, orderBy, limit } from "firebase/firestore";


const context = "ResultHistory.js";

var store = null;
var self = null;

@inject("appStore")
@observer
class ResultHistory extends React.Component {

    constructor(props) {
        super(props);
        store = this.props.appStore;
        self = this;


    }



    componentDidMount = () => {
        console.log("Page : ", context)
        this.handleFindResultButtonClick();
    }

    handleFindResultButtonClick = (e) => {
        // var date = document.getElementById("inputDate").value;
        // console.log("inputDate = >", date)
        // if (date.trim() === "") {
        //     alert("Invalid Date")
        //     return;
        // } else {
        store.setUpcommingEventsData(null)
        var firebase = firebaseApp();
        var db = firebase.firestore();
        var docRef = db.collection("today");


        docRef.orderBy("timestamp", "desc").limit(60).get().then((doc) => {
            console.log('docccc ========>>>>>>>>>> ', doc.size);
            if (doc.size > 0) {
                console.log("Document data:", doc.docs);
                store.setUpcommingEventsData(doc.docs)
            } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
                store.setUpcommingEventsData("NO_DATA_FOUND")
            }
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
        // }

    }

    generatetableUi = (upcommingEventssData, configData) => {
        console.log("tableUUiData => ", JSON.stringify(upcommingEventssData))
        if (upcommingEventssData === "NO_DATA_FOUND") {
            console.log("true")
            var ui = configData.timings.map(function (content, index) {
                return (
                    <tr>
                        <td>{content}</td>
                        <td><input id={"input_" + content} type="text" maxLength="4" defaultValue="No Data Found" disabled></input></td>
                    </tr>
                )
            });

            return ui;
        } else {
            var ui = upcommingEventssData.map(function (_content, index) {
                const content_doc = _content.data();
                const content_data = content_doc.data;
                const table_ui_list = [];

                console.log('----------------------------------------------------', index)
                var row_ui = content_data.map(function (content, index2) {
                    console.log("content => ", content);

                    if (content.is_current === true) {
                        return (
                            // <tr>
                            //     <td>{content.time}</td>
                            <td><input id={"input_" + content.time} type="text" maxLength="4" defaultValue={"Up coming"} disabled></input></td>
                            // </tr>
                        )
                    } else if (content.is_shown === true) {
                        var value_res = content.value;
                        var lastElement = value_res.slice(value_res.length - 1);
                        var allOtherElement = value_res.slice(0, -1);
                        return (
                            // <tr>
                            //     <td>{content.time}</td>
                            // <td style={{ textAlign: 'center' }}>{content.value}</td>


                            <td className="resulttext">
                                <span>{allOtherElement}</span>
                                <br />
                                <span style={{ fontWeight: 900 }}>{lastElement}</span>
                            </td>
                            // </tr>
                        )
                    } else {
                        return (
                            // <tr>
                            //     <td>{content.time}</td>
                            <td><input id={"input_" + content.time} type="text" maxLength="4" defaultValue={"UpComing"} disabled></input></td>
                            // </tr>
                        )
                    }
                });

                const table = (<table id="customers" className="center-item" style={{ width: "90%", textAlign: "center", marginBottom: 20 }}>
                    <tr>
                        <th style={{ textAlign: 'center' }} colspan="10">{content_data[0].date}</th>
                    </tr>
                    <tr>
                        {row_ui}
                    </tr>
                </table>);

                table_ui_list.push(table);
                return table_ui_list;
            });

            return ui;
        }
    }



    render() {
        var upcommingEventssData = store.getUpcommingEventsData;
        var configData = store.getFbConfigData;
        var table_ui = null
        if (upcommingEventssData) {
            table_ui = this.generatetableUi(upcommingEventssData, configData)
        }
        return (
            <div className="center-item" style={{ backgroundColor: "azure" }}>
                <div style={{ textAlign: "center", margin: "auto", width: "50%", paddingTop: 50, marginBottom: 50 }}>
                    <h1>Goa Fatafat Lottery Results</h1>
                    {/* <input className="center-item" style={{ padding: 10 }} type="date" id="inputDate"></input>
                    <button onClick={(e) => this.handleFindResultButtonClick(e)} className="green-button"> Find Result </button> */}
                </div>
                {/* <table id="customers" className="center-item" style={{ width: '80%' }}>
                    <tr>
                        <th>Time</th>
                        <th>Score</th>
                    </tr> */}
                {table_ui}
                {/* </table> */}
                <div className="center-item" style={{ textAlign: "center", margin: "auto", width: "50%", paddingTop: 50, marginBottom: 50 }}>

                </div>

            </div>
        )

    }
}

export default withRouter(ResultHistory);
