import React from "react";
import { observer, inject } from 'mobx-react';
import { withRouter } from "react-router-dom";
import { Link } from 'react-router-dom';

import { appendScript } from "../Utils/AppendScript"

const context = "Home.js";
var store = null;
var self = null;
var currentData = null;
var nextEventDateTime = null;
var lastDarwTime = "NA";
var lastDarwValue = "NA";
var isLiveHidden = true;
@inject("appStore")
@observer
class Home extends React.Component {

    constructor(props) {
        super(props);
        store = this.props.appStore;
        store.setCurrentPage("create_job")
        self = this;
        this.state = {
            value: "xyz",
            currentData: null
        };
    }

    // UNSAFE_componentWillMount = () => {


    //     // store.setAuthentication("null")
    // }

    componentDidMount = () => {
        // appendScript("lotteryMachine.js")



    }

    clickStart = (e) => {
        console.log("clicked start 1..")
        window.start()
    }

    shuffleArray = (array) => {
        let currentIndex = array.length, randomIndex;

        // While there remain elements to shuffle.
        while (currentIndex != 0) {

            // Pick a remaining element.
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;

            // And swap it with the current element.
            [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]];
        }

        return array;
    }

    handleCurrentData = (cur_data) => {
        currentData = null
        console.log("cur+data => ", JSON.stringify(cur_data))
        try {
            var value_original = cur_data['value'].toString().split('');
            var res = self.shuffleArray(value_original.slice(0, -1)).concat(value_original.slice(-1));
            var value_shuffled = parseInt(res.join(''));
            console.log('value_shuffled =>> ', value_shuffled);
            cur_data['value_shuffled'] = value_shuffled;
            console.log('cur_data =>>> ', cur_data)
        } catch (error) {
            
        }
      
        if (cur_data) {
            let IS_EXECUTED = false;
            let a = setTimeout(function () {
                if (IS_EXECUTED) {
                    return;
                }
                IS_EXECUTED = true;
                if (a) {
                    window.clearTimeout(a);
                }
                try {
                    console.log('time =>> ', new Date());

                    window.initCanvas(cur_data['time'], cur_data['value'], cur_data['value'], true)
                } catch (error) {
                    console.log('error ===>> ', error)
                }

            }, 1000);
        } else {
            setTimeout(function () {
                try {
                    window.initCanvas("", "", "", false)
                } catch (error) {

                }

            }, 100);

        }
    }

    generateResultTableUi = (todayEventData) => {
        isLiveHidden = true;
        nextEventDateTime = null;
        var ui = []
        for (var i = 0; i < todayEventData.data.length; i++) {
            var d = todayEventData.data[i]
            if (d['is_current'] === true) {
                isLiveHidden = false
                ui.push(<tr key={Math.random()}>
                    <td style={{ height: "52px", fontSize: "18px", fontWeight: "bold" }}
                        className="resulttime" width="30%">{d['time']}</td>
                    <td className="resulttext" width="20%"></td>
                </tr>)
                currentData = d;
            } else {
                var value_res = d['value']
                var lastElement = value_res.slice(value_res.length - 1);
                var allOtherElement = value_res.slice(0, -1);
                ui.push(<tr key={Math.random()}>
                    <td style={{ height: "52px", fontSize: "18px", fontWeight: "bold" }}
                        className="resulttime" width="30%">{d['time']}</td>
                    <td className="resulttext" width="20%">
                        <span>{allOtherElement}</span>
                        <br />
                        <span style={{ fontWeight: 900 }}>{lastElement}</span>
                    </td>
                </tr>)
            }

            if (d['is_shown'] === false) {
                if (nextEventDateTime === null) {
                    nextEventDateTime = d['date'] + " " + d['time']
                }
            } else {
                if (d['is_current'] === false) {
                    lastDarwTime = d['time']
                    lastDarwValue = d['value']
                }


            }
        }

        // window.initCanvas()
        this.handleCurrentData(currentData)
        return ui;
    }

    handleClickMuteUnmute = (e) => {
        // alert("hi")
        window.handleClickMuteUnmute(e)
    }

    render() {
        var res = "98"

        res = store.getIsAuthenticated;
        console.log(res)

        console.log("==> ", store.getIsAuthenticated)

        var todayEventData = store.getTodayEventsData;
        var resultTableUi;
        if (todayEventData) {
            resultTableUi = this.generateResultTableUi(todayEventData)
        }
        return (
            <div key={Math.random()}>
                <table key={Math.random()} cellSpacing="1" cellPadding="1" border="0" align="center" style={{ width: "50%" }}>
                    <tbody>
                        <tr key={Math.random()}>
                            <td style={{ textAlign: "center", bgColor: "#999999" }}></td>
                        </tr>
                        <tr key={Math.random()}>
                            <td>
                                <table key={Math.random()} width="100%" cellSpacing="0" cellPadding="0" border="1" style={{ backgroundColor: "green" }}>
                                    <tbody>
                                        <tr key={Math.random()}>
                                            <td className="title_white_bold" style={{ textAlign: "center", width: "40%", bgColor: "#267c58" }}>
                                                <span
                                                    style={{ textAlign: "enter", fontSize: "24px", fontWeight: "bold", color: "#FFFF00", marginLeft: "20px", float: "left" }}>Goa
                                                    Fatafat</span>
                                                {/* <span className="title_white_bold">Today :
                                                    <span id="TodatyDate">30 Nov 2021</span></span>
                                                <span className="title_white_bold"></span><span className="title_white_bold">
                                                    <span id="NowTime">11:10:59 AM</span></span> */}

                                                <Link to="/history" style={{ color: "white" }}> Result History</Link>
                                                <div style={{ float: "right" }}>
                                                    Next &nbsp; &nbsp;<span>
                                                    </span>
                                                    &nbsp;<span id="lblVisibleDrawTime"><span style={{ marginLight: "20px" }}
                                                        id="NextDrowTime">{nextEventDateTime}
                                                    </span>&nbsp; &nbsp;</span></div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr key={Math.random()}>
                            <td className="pagebgcolor" align="center" style={{
                                backgroundColor: "cornsilk"
                            }}>
                                <div key={Math.random()} name="new_ticket" id="new_ticket" autocomplete="off" onkeypress="return event.keyCode != 13;">
                                    <table key={Math.random()} width="100%" cellSpacing="1" cellPadding="1" border="0">
                                        <tbody>
                                            <tr key={Math.random()}>
                                                <td valign="top" style={{ border: "2px solid black" }}>
                                                    <i id="mute_unmute" onClick={(e) => self.handleClickMuteUnmute(e)} className='fas fa-volume-mute' style={{ fontSize: 25, margin: 10 }}></i>
                                                    <img src="giphy.gif" id="liveImage" hidden={isLiveHidden} style={{ height: 50, float: "right" }}></img>
                                                    <table key={Math.random()} style={{ marginLeft: "auto", marginRight: "auto" }}>

                                                        <div key={Math.random()} id="ballMachine" style={{ textAlign: "center" }}></div>
                                                        <canvas id="myCanvasResultImage"
                                                            style={{ height: "95px", width: "185px", marginLeft: "39%" }}></canvas>
                                                        {/* <input id="inputDigits" type="text"></input>
                                                        <button onClick={(e) => this.clickStart(e)}>click</button> */}
                                                        {store.getIsAuthenticated ? <div><Link to="dashboard">Go To Dashboard</Link> <Link to="logout">Logout</Link></div> : <div></div>}
                                                    </table>
                                                </td>
                                                <td style={{ textAlign: "center", width: "30%", valign: "top" }}>
                                                    <span id="LastDrawTime" style={{ fontSize: "20pt" }}>{lastDarwTime}</span>
                                                    <br />
                                                    <div key={Math.random()} className="row" align="center">
                                                        <div key={Math.random()} className="col s12">
                                                            <div key={Math.random()} className="content"
                                                                style={{
                                                                    height: "110px", width: "150px", paddingTop: "20px", backgroundRepeat: "no-repeat", backgroundPosition: "center", border: "0px solid #ffffff"
                                                                }}
                                                                align="center" >
                                                                <table key={Math.random()}>
                                                                    <tbody>
                                                                        <tr key={Math.random()}>
                                                                            <td> <h3 className="animate-charcter" id="lastResult">{lastDarwValue}</h3> </td>

                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div key={Math.random()} style={{ width: "100%", maxHeight: "345px", overflow: "auto", verticalAlign: "top;" }}
                                                        id="resultChartDiv">
                                                        <table key={Math.random()} id="resultChart" width="100%" cellSpacing="1" cellPadding="1"
                                                            border="1">
                                                            <tbody>
                                                                {resultTableUi}


                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </td>
                        </tr>

                    </tbody>
                </table >
            </div >
        )

    }
}

export default withRouter(Home);
