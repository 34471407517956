import { observable, computed, action, makeObservable } from "mobx";

export default class AppHelperStore {
    constructor() {
        makeObservable(this);
    }

    @observable isAuthenticated = false;
    @observable userIdToken = null;
    @observable fileToUpload = null;
    @observable isPageLoading = false;
    @observable loadingMessage = "null";
    @observable currentPageName = "NA";
    @observable previousJobDetails = [];
    @observable userProfileDetails = null;
    @observable fbConfigData = null
    @observable upcommingEventsData = null;
    @observable historyEveentsData = null;
    @observable todayEventsData = null;

    @action setAuthentication(is_authenticated) {
        console.log("In Store => Set Is Authenticated => " + is_authenticated);
        this.isAuthenticated = is_authenticated;
    }

    @action setUserIdToken(id_token) {
        // console.log("In Store => Set Is Authenticated => " + is_authenticated);
        this.userIdToken = id_token;
    }
    @action setFileToUpload(file) {
        this.fileToUpload = file;
    }

    @action setIsPageLoading(flag, msg = "null") {
        this.isPageLoading = flag;
        this.loadingMessage = msg;
    }
    @action setCurrentPage(page_name) {
        this.currentPageName = page_name;
    }
    @action setPreviousJobDetails(prev_job_details) {
        this.previousJobDetails = prev_job_details;
    }
    @action setUserProfileDetails(user_profile_details) {
        this.userProfileDetails = user_profile_details;
    }
    @action setFbConfigData(config) {
        this.fbConfigData = config
    }
    @action setUpcommingEventsData(data) {
        this.upcommingEventsData = data
    }
    @action setHistoryEventsData(data) {
        this.historyEveentsData = data
    }
    @action setTodayEventsData(data) {
        this.todayEventsData = data
    }


    @computed
    get getIsAuthenticated() {
        console.log("In Store => get Is Authenticated => " + this.isAuthenticated);
        return this.isAuthenticated;
    }

    @computed
    get getUserIdToken() {
        return this.userIdToken;
    }

    @computed
    get getFileToUpload() {
        return this.fileToUpload;
    }
    @computed
    get getIsPageLoading() {
        return this.isPageLoading;
    }
    @computed
    get getLoadingMessage() {
        return this.loadingMessage;
    }
    @computed
    get getCurrentPage() {
        return this.currentPageName;
    }
    @computed
    get getPreviousJobDetails() {
        return this.previousJobDetails;
    }
    @computed
    get getUserProfileDetails() {
        return this.userProfileDetails;
    }

    @computed get getFbConfigData() {
        return this.fbConfigData;
    }
    @computed get getUpcommingEventsData() {
        return this.upcommingEventsData;
    }
    @computed get getHistoryEventsData() {
        return this.historyEveentsData;
    }
    @computed get getTodayEventsData() {
        return this.todayEventsData;
    }

}
