import React from "react";
import { observer, inject } from 'mobx-react';
import { withRouter } from "react-router-dom";
import { firebaseApp } from "../Utils/FirebaseInit";

const APP_CONFIG = require("../AppConfig/appConfig.json")

const context = "FirebaseHandler.js";

var store = null;
var self = null;





@inject("appStore")
@observer
class FirebaseHandler extends React.Component {

    constructor(props) {
        super(props);
        store = this.props.appStore;
        self = this;
    }



    componentDidMount = () => {
        self.initiateFirebaseJobListner()
        self.getPreConfigTemplate()
        var firebase = firebaseApp();
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                //   User is signed in.
                let user = firebase.auth().currentUser;
                let uid
                if (user != null) {
                    uid = user.uid;
                    console.log("UID => ", uid)
                    console.log("NAme => ", user.displayName)
                    console.log("Email Id => ", user.email)
                    user.getIdToken(true).then(function (idToken) {  // <------ Check this line
                        console.log(idToken); // It shows the Firebase token now
                        store.setUserIdToken(idToken)
                        var user_profile_details = {
                            "full_name": user.displayName,
                            "email_id": user.email,
                            "profile_image": user.photoURL,
                            "id_token": idToken
                        }

                        store.setUserProfileDetails(user_profile_details)

                        store.setAuthentication(true)

                    });
                    // console.log("Id token  => ", user.getIdTokenResult())

                    // store.setAuthentication(true)
                    this.props.history.push("/")
                }

            } else {
                store.setAuthentication(false)
                console.log("No User Is LogedIn")
                // store.setAuthentication(true)
                // this.props.history.push("/login")
            }
        });



    }

    getPreConfigTemplate = () => {
        var firebase = firebaseApp();
        var db = firebase.firestore();
        var docRef = db.collection("config").doc("config");

        docRef.get().then((doc) => {
            if (doc.exists) {
                console.log("Document data:", doc.data());
                store.setFbConfigData(doc.data())
            } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
            }
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
        // self.props.history.push("/")

    }


    initiateFirebaseJobListner = () => {
        var firebase = firebaseApp();
        var db = firebase.firestore();
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        today = yyyy + '-' + mm + '-' + dd;
        db.collection("today").doc(today)
            .onSnapshot((querySnapshot) => {
                console.log(querySnapshot.data())
                store.setTodayEventsData(querySnapshot.data())
            });
        // console.log("Current cities in CA: ", cities.join(", "));


    }

    render() {
        return (
            <div>

            </div>
        )

    }
}

export default withRouter(FirebaseHandler);
